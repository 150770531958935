<template>
  <div class="page">
    <headerSwiper />
    <div class="content">
      <div class="content-title">产品中心</div>
      <div class="content-sub-title">我们始终秉持以匠人之心的信念去打造每一款产品，精益求精，不断创新，让产品融入生活</div>
      <div class="draw-line"></div>
      <a href="/product/?type=intelligentHardware" class="product-info-left">
        <div><img src="@/assets/images/home/1.png" alt=""></div>
      </a>
      <div class="product-info-right">
        <a href="/product/?type=intelligentHardware"><img src="@/assets/images/home/2.png" alt=""></a>
        <a href="/product/?type=intelligentHardware"><img src="@/assets/images/home/3.png" alt=""></a>
      </div>
    </div>
    <div class="content">
      <div class="content-title">新一代的智能解决方案</div>
      <div class="content-sub-title">更智能  更便捷  更多元化的智能解决方案</div>
      <div class="draw-line"></div>
      <a href="/solution" class="solution-list">
        <div>
          <img src="@/assets/images/home/4.png" alt="" />
          <div><img src="@/assets/images/home/5.png" alt="" /></div>
        </div>
        <div>
          <img src="@/assets/images/home/6.png" alt="" />
          <div><img src="@/assets/images/home/7.png" alt="" /></div>
        </div>
        <div>
          <img src="@/assets/images/home/8.png" alt="" />
          <div><img src="@/assets/images/home/9.png" alt="" /></div>
        </div>
        <div>
          <img src="@/assets/images/home/10.png" alt="" />
          <div><img src="@/assets/images/home/11.png" alt="" /></div>
        </div>
        <div>
          <img src="@/assets/images/home/12.png" alt="" />
          <div><img src="@/assets/images/home/13.png" alt="" /></div>
        </div>
      </a>
    </div>
    <div style="background: #f4f4f4">
      <div class="content">
        <div class="content-title">视频中心</div>
        <div class="content-sub-title">关于深圳多知能源物联有限公司出品，以下主题将帮助您更好地了解</div>
        <a class="more-button" href="/about/companyVideo"><div>查看更多</div></a>
        <div class="video-info">
          <a :href="'/about/video/?id='+index" class="video-info-item" v-for="(item, index) in videoList" :key="index">
            <div>
              <div class="iconfont duozhi-icon-bofang"></div>
              <img :src="item.img" alt="">
            </div>
            <div class="video-info-item-title">{{item.title}}</div>
          </a>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-title">深圳多知能源物联有限公司</div>
      <div class="make-grand-gestures">
        <div v-for="(item, index) in makeGrandGestures" :key="index">
          <img :src="item.logo" alt="">
          <!-- <div>{{item.title}}</div>
          <div>{{item.subTitle}}</div> -->
        </div>
      </div>
    </div>
    <div style="background: #f2f6f7">
      <div class="content">
        <div class="content-title">新闻咨询</div>
        <div class="content-sub-title">多知最新动态发布，行业咨询分享</div>
        <div class="draw-line"></div>
        <div class="news-info">
          <div class="news-info-left">
            <img src="/staticImg/news2.png" alt="" srcset="">
            <div>重磅消息：优气宝、优气达小程序即将上线！</div>
          </div>
          <a :href="'/consultingTrends/detail/?id='+(index+1)" v-for="(item, index) in newsData" :key="index" class="news-item">
            <div>{{item.time_d}}</div>
            <div>{{item.time_m}}-{{item.time_y}}</div>
            <div>{{item.title}}</div>
            <div>{{item.content}}</div>
            <div>更多 ></div>
          </a>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import img17 from "@/assets/images/home/17.png"
import img18 from "@/assets/images/home/18.png"
import img19 from "@/assets/images/home/19.png"
import img20 from "@/assets/images/home/20.png"
import img21 from "@/assets/images/home/21.png"
import img22 from "@/assets/images/home/22.png"
import img23 from "@/assets/images/home/23.png"
import img24 from "@/assets/images/home/24.png"
import headerSwiper from "@/components/headerSwiper.vue"
import newsData from "@/assets/hypocritical/news.js"
import videoList from "@/assets/hypocritical/videoList.js"
newsData.map(it => {
  it.content = it.content.replace(/<\/?.+?\/?>/g, "").replace(/&[a-zA-Z]+;/g, "")
})
console.log(newsData,"newsData")
export default {
  components: {headerSwiper},
  name: "Home",
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content:
          "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  data(){
    return{
      makeGrandGestures: [
        {logo:img17, title: "2005年成立", subTitle: "海:“海纳百川,有容乃大”"},
        {logo:img18, title: "国家高新技术企业", subTitle: "专注于智能家居&智慧消防领域"},
        {logo:img19, title: "自主研发创新", subTitle: "16年专注自主研发"},
        {logo:img20, title: "质量保证", subTitle: "引进国际尖端设备,如德国"},
        {logo:img21, title: "产品认证达100+项", subTitle: "国内外认证检测"},
        {logo:img22, title: "荣获专利88+项", subTitle: "发明专利,实用新型专利."},
        {logo:img23, title: "产品设计美学", subTitle: "荣获德国红点、IF设计大奖"},
        {logo:img24, title: "布局全球市场", subTitle: "产品销往100+个国家"},
      ],
      newsData,
      videoList
    }
  },
  created() {
  },
};
</script>
<style lang="scss" scoped>
.content{
  padding: 60px 0 0 0;
  max-width: 1600px;
  .content-title{
    font-size: 30px;
    color: #6d6d6d;
    margin: 0;
  }
  .content-sub-title{
    margin: 20px auto;
    font-size: 16px;
    color: #666;
  }
  .draw-line{
    width: 250px;
    height: 3px;
    margin: 0 auto;
    position: relative;
    background-color: #e6e6e6;
    margin-bottom: 50px;
    &::before{
      content: "";
      position: absolute;
      width: 50px;
      height: 5px;
      bottom: 0;
      left: calc(50% - 25px);
      background: #2da0db;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
      transition-delay: 0s;
    }
  }
  .draw-line:hover{
    &::before{
      width: 250px;
      left: 0;
    }
  }
}
.product-info-left{
  position: relative;
  display: inline-block;
  width: 48%;
  margin-right: 4%;
  overflow: hidden;
  position: relative;
  & > div{
    width: 100%;
    height: 0;
    padding-bottom: 88%;
    transition: all .2s;
    img{
      width: 100%;
    }
  }
  & > div:hover{
    transform: scale(1.1);
  }
}
.product-info-right{
  display: inline-block;
  width: 47%;
  & > a:nth-child(1){
    display: inline-block;
    margin-bottom: 3.5%;
  }
  & > a{
    width: 100%;
    img{
      width: 100%;
      transition: all .2s;
    }
  }
  & > a:hover{
    img{
      transform: scale(1.1);
    }
  }
}
.solution-list{
  width: 100%;
  padding-bottom: 60px;
  position: relative;
  display: flex;
  transition: all .3s;
  & > div{
    width: 20%;
    position: relative;
    & > img:nth-child(1){
      width: 100%;
      height: 100%;
    }
    & > div:nth-child(2){
      position: absolute;
      bottom: -100%;
      left: 0;
      height: 0;
      bottom: 0;
      width: 100%;
      transition: all 1s;
      background: #2da0db;
      overflow: hidden;
      img{
        width: 100%;
      }
    }
  }
  & > div:hover{
    & > div:nth-child(2){
      height: 110%;
    }
  }
}
.more-button{
  position: relative;
  padding: 5px 10px;
  border: 1px solid #2da0db;
  width: fit-content;
  margin: auto;
  position: relative;
  display: block;
  cursor: pointer;
  &::before{
    content: "";
    width: 0;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background: #2da0db;
    z-index: 0;
    transition: all .3s;
  }
  & > div{
    position: relative;
    z-index: 1;
    transition: all .3s;
  }
}
.more-button:hover{
  &::before{
    width: 100%;
  }
  & > div{
    color: white;
  }
}
.video-info{
  display: flex;
  justify-content: space-between;
  padding: 60px 0;
  .video-info-item{
    width: 33%;
    cursor: pointer;
    position: relative;
    img{
      width: 100%;
      height: 16vw;
      max-height: 298px;
    }
    & > div:nth-child(1){
      position: relative;
      &::before{
        content: "";
        position: absolute;
        width: 0;
        height: 5px;
        left: 50%;
        bottom: 4px;
        background: #2da0db;
        transition: all .5s;
      }
    }
    .iconfont{
      position: absolute;
      font-size: 60px;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
      color: white;
      transition: all .1s;
    }
    .video-info-item-title{
      line-height: 50px;
      color: #686767;
    }
  }
  .video-info-item:hover{
    & > div:nth-child(1){
      &::before{
        width: 100%;
        left: 0;
      }
    }
    .iconfont{
      font-size: 50px;
      left: calc(50% - 25px);
      top: calc(50% - 25px);
    }

  }
}
.make-grand-gestures{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 60px;
  & > div{
    width: 23%;
    // min-height: 230px;
    margin: 1%;
    // background: #f8f8f8;
    transition: all .2s;
    cursor: pointer;
    // height: 0;
    // padding-bottom: 14.72%;
    img{
      width: 100%;
      // height: 100%;
      // margin-top: 20px;
    }
    & > div:nth-child(2){
      margin: 10px 0;
      padding: 0;
      font-weight: normal;
      font-size: 17px;
      color: #656565;
    }
    & > div:nth-child(3){
      margin: 10px 0;
      padding: 0;
      font-weight: normal;
      font-size: 14px;
      color: #656565;
    }
  }
  & > div:hover{
    transform: translateX(-5px) translateY(-5px);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
}
.news-info{
  display: flex;
  padding-bottom: 110px;
  .news-info-left{
    position: relative;
    width: 38%;
    margin-right: 2%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    img{
      width: 100%;
    }
    div{
      line-height: 55px;
      color: #fff;
      background: #2da0db;
    }
  }
  .news-item{
    width: 19%;
    background: #fff;
    text-align: left;
    margin-left: 1%;
    height: auto;
    cursor: pointer;
    & > div:nth-child(1){
      margin: 20px 20px 0 20px;
      width: 50px;
      font-size: 34px;
      line-height: 45px;
      border-bottom: 2px solid #999;
      color: #2da0db;
    }
    & > div:nth-child(2){
      width: 100px;
      font-size: 16px;
      margin: 0 20px 0 20px;
      display: block;
      height: 45px;
      line-height: 45px;
      color: #999;
    }
    & > div:nth-child(3){
      font-size: 16px;
      padding: 0 20px;
      color: #333;
      display: block;
    }
    & > div:nth-child(4){
      margin: 20px;
      color: #767676;
      font-size: 14px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
    }
    & > div:nth-child(5){
      color: #2da0db;
      padding: 18px 20px;
      height: 20px;
      font-size: 14px;
    }
  }
  .news-item:hover{
    background: #2da0db;
    & > div{
      color: white;
    }

  }
}

@media screen and (max-width: 600px) {
  .content-title{
    font-size: 24px!important;
  }
  .content-sub-title{
    font-size: 14px!important;
  }
  .video-info{
    flex-direction: column;
    & > a{
      width: 100%!important;
      height: 64vw;
      & > div > img{
        height: 50vw!important;
      }
    }
  }
  .make-grand-gestures{
    & > div{
      width: 48%;
    }
  }
  .news-info{
    flex-wrap: wrap;
    .news-info-left, .news-item{
      width: 100%;
    }
  }
  .solution-list{
    
    flex-wrap: wrap;
    justify-content: center;
    & > div{
      width: 50%;
    }
  }
}
</style>
